export const DashboardBureauInfo = [{
  key: 'rapport_aantallen',
  title: 'Definitie Onderhanden Werk',
  description: '\
    <h3>Totaal</h3> \
    <ul> \
      <li>Alle rapporten in deze status</li> \
      <li>Zonder de tag: Testdossier</li> \
    </ul> \
    <h3>Externe Partij</h3> \
    <ul> \
      <li>Een subdeel van: Totaal</li> \
      <li>Met de variant: Aannemersvariant, WoCo v1, WoCo v2 (Oud & Huidige)</li> \
      <li>Met de status: In Opname</li> \
    </ul> \
    <h3>Toekomstig Gepland</h3> \
    <ul> \
      <li>Een subdeel van: Totaal</li> \
      <li>Wat niet valt in: Externe Partij</li> \
      <li>Met een geplande bezoekdatum in de toekomst</li> \
    </ul> \
    <h3>Vervolgafspraak</h3> \
    <ul> \
      <li>Een subdeel van: Totaal</li> \
      <li>Wat niet valt in: Externe Partij</li> \
      <li>Wat niet valt in: Toekomstig Gepland</li> \
      <li>Met een geplande vervolgafspraak in de toekomst</li> \
    </ul> \
    <h3>Onderhanden werk</h3> \
    <ul> \
      <li>Een subdeel van: Totaal</li> \
      <li>Wat niet valt in: Externe Partij</li> \
      <li>Wat niet valt in: Toekomstig Gepland</li> \
      <li>Wat niet valt in: Vervolgafspraak</li> \
    </ul> \
    <p>&nbsp;</p> \
    <h3>Formule</h3> \
    <p>Onderhanden werk = Totaal - Externe Partij - Toekomstig Gepland - Vervolgafspraak</p> \
  ',
},
{
  key: 'contract_kpis',
  title: 'Contract KPI\'s',
  description: '\
    <h3>Standaard</h3> \
    <p>Alle rapporten niet zijnde 1 van onderstaande categorieën.</p> \
    <p><strong> \
      Doorlooptijd doelstelling = 90%</strong> <br> \
      \<strong>FTR doelstelling = 90%</strong> \
    </p> \
    <h3>Standaard 30+</h3> \
    <p>Alle rapporten niet zijnde 1 van onderstaande categorieën en met 31 of meer schades.</p> \
    <p><strong> \
      Doorlooptijd doelstelling = 75%</strong> <br> \
    </p> \
    <h3>Herzien rapport</h3> \
    <p>Alle rapporten die (op basis van een bestaand rapport welke verstuurd was naar aanvrager) als copy zijn aangemaakt met het verzoek voor een nader advies.</p> \
    <h3>Special</h3> \
    <p>Alle rapporten die als IMG zijn gemarkeerd als een special.</p> \
    <h3>Bezwaar</h3> \
    <p>Alle rapporten met een rapporttype: Bezwaar, Bezwaar Addendum en Bezwaar Hoorzitting. Doorlooptijd en FTR is niet opgenomen in het contract.</p> \
    <h3>Aannemersvariant</h3> \
    <p>Alle rapporten met een rapporttype: Aannemersvariant en Aannemersvariant OUD. Doorlooptijd en FTR is niet opgenomen in het contract.</p> \
    <h3>Woco v1</h3> \
    <p>Alle rapporten met een rapporttype: WoCo v1 en WoCo v1 OUD. Doorlooptijd en FTR is niet opgenomen in het contract.</p> \
    <h3>Woco v2</h3> \
    <p>Alle rapporten met een rapporttype: WoCo v2 en WoCo v2 OUD. Doorlooptijd en FTR is niet opgenomen in het contract.</p> \
  ',
},
{
  key: 'status',
  title: 'Operationele Sleutelgetallen',
  description: '<p>Deze getallen laten zien hoeveel dossiers zich in de respectievelijke status bevinden.</p> \
    <ul> \
      <li>Groen: bij 0-5 dossiers</li> \
      <li>Oranje: bij 6-10 dossiers</li> \
      <li>Rood: bij 11 of meer dossiers</li> \
    </ul>',
},
{
  key: 'availability',
  title: 'Opgegeven beschikbaarheid',
  description: '<p>De opgegeven beschikbaarheid geschied in uren. Bij de doelstellingen wordt gesproken over afspraken. Daarvoor hanteren we een vaste sleutel:</p> \
    <p><strong>1 afspraak = 4 uur</strong></p> \
    <p> \
      Het linker getal is het door bureau opgegeven aantal afspraken. \
      Het rechter getal is de taakstelling/garantie IMG. \
    </p>\
    <p> \
      In deze getallen neem je niet mee: de Aannemersvariant, de Woco v1 en de Woco v2 dossiers. \
    </p>',
},
{
  key: 'planning',
  title: 'Geplande en gelopen afspraken',
  description: '<p>Alle afspraken in de periode welke niet zijn geannuleerd.</p> \
  <p> \
    Het linker getal is het aantal ingeplande afspraken door planning IMG. \
    Het rechter getal is de taakstelling voor planning IMG. \
    </p>\
    <p><strong>1 afspraak = 1 ingeplande afspraak (realtime)</strong></p> \
    <p> \
      In deze getallen neem je niet mee: de Aannemersvariant, de Woco v1 en de Woco v2 dossiers. \
    </p>',
},
{
  key: 'productie_week',
  title: 'Productie doelstellingen - WEEK',
  description: '<p>Het aantal rapporten wat in de week is verstuurd naar IMG ten opzichte van de gestelde doelstelling. Er wordt gekeken naar de ondertekeningsdatum in het rapport. De statistieken gaan per ManagementInfo categorie. De definties van deze categorien staan bij het i-tje van \'Contract KPI\'s\'</p> \
  <p>Groen wanneer gelijk of meer, en rood wanneer minder dan:</p> \
  <h3>Een week in het verleden</h3> \
  <ul> \
      <li>Groen: 100%</li> \
      <li>Oranje: 90% - 99%</li> \
      <li>Rood: 0% - 89%</li> \
  </ul> \
  <h3>In de huidige week ( groen | oranje )</h3> \
  <ul> \
      <li>Maandag: 20% | 18%</li> \
      <li>Dinsdag: 40% | 36%</li> \
      <li>Woensdag: 60% | 54%</li> \
      <li>Donderdag: 80% | 72%</li> \
      <li>Vrijdag: 100% | 90%</li> \
  </ul> \
  ',
},
{
  key: 'productie_maand',
  title: 'Productie doelstellingen - MAAND',
  description: '<p>Het aantal rapporten wat in de maand is verstuurd naar IMG ten opzichte van de gestelde doelstelling. Er wordt gekeken naar de ondertekeningsdatum in het rapport. De statistieken gaan per ManagementInfo categorie. De definties van deze categorien staan bij het i-tje van \'Contract KPI\'s\'</p> \
  <p>Groen wanneer gelijk of meer, en rood wanneer minder dan:</p> \
  <h3>Een maand in het verleden</h3> \
  <ul> \
      <li>Groen: 100%</li> \
      <li>Oranje: 90% - 99%</li> \
      <li>Rood: 0% - 89%</li> \
  </ul> \
  <h3>In de huidige maand</h3> \
  <p>Groen wanneer gelijk of meer dan:</p> \
  <ul> \
      <li>29 april: 29/30 * doelstelling</li> \
      <li>30 april: 30/30 * doelstelling</li> \
      <li>1 mei: 1/31 * doelstelling</li> \
      <li>2 mei: 2/31 * doelstelling</li> \
      <li>3 mei: 3/31 * doelstelling</li> \
      <li>... etc</li> \
    </ul> \
    <p>Oranje indien 90% van bovenstaande formule.</p> \
    ',
}];

export const DashboardPlanningInfo = [
  {
    key: 'rapport_aantallen_lld',
    title: 'Lang Lopende Dossiers',
    description: '<p>In dit overzicht worden de aantallen weergegeven van de LDD dossiers die nog bij planning staan, dit zijn dossiers die nog ingepland moeten worden. Een dossier staat bij planning wanneer deze de status Planning of Teruggezet planning heeft. Een dossier is een LLD dossier wanneer deze de tag LLD heeft.</p>',
  },
  {
    key: 'rapport_table_lld',
    title: 'Gemaakte afspraken: LLD',
    description: '<p>In dit overzicht staan voor de afspraken die voor de LLD dossiers gemaakt zijn voor de deskundige. Per dag van de week dat de afspraken aangemaakt zijn.</p><p><i>Exclusief geannuleerde dossiers en testdossiers.</i></p>',
  },
  {
    key: 'rapport_aantallen_onderzoeken',
    title: 'Onderzoeken',
    description: '<p>In dit overzicht worden de aantallen weergegeven van de onderzoeken die bij planning staan, dit zijn dossiers die nog ingepland moeten worden of waarvoor opnieuw een afspraak gemaakt moet worden.</p> \
    <p>Een dossier staat bij planning wanneer deze de status Planning of Teruggezet planning heeft.</p> \
    <p>Een dossier is een onderzoek wanneer deze een rapporttype heropname heeft of een planning status vervolgopname, nader onderzoek of aanvullend onderzoek.</p>',
  },
  {
    key: 'rapport_table_onderzoeken',
    title: 'Gemaakte afspraken: Onderzoeken',
    description: '<p>In dit overzicht staan de afspraken die voor de onderzoeken gemaakt zijn voor de deskundige, per dag van de week dat de afspraken aangemaakt zijn. Bij onderzoeken tellen alle afspraken mee waarvoor een onderzoek is ingepland, de soort afspraak is hierbij te herkennen aan de substatus(planningstatus).</p> \
    <p><i>Exclusief geannuleerde dossiers en testdossiers.</i></p>',
  },
  {
    key: 'rapport_aantallen_opname',
    title: 'Opname',
    description: '<p>In dit overzicht worden de aantallen weergegeven van de ingeplande dossiers bij de verschillende bureaus. </p>',
  },
  {
    key: 'rapport_table_opname',
    title: 'Gemaakte afspraken: Opname',
    description: '<p>In dit overzicht staan de afspraken die voor de opname gemaakt zijn voor de deskundige, per dag van de week dat de afspraken aangemaakt zijn. Onder opname tellen alleen de reguliere afspraken mee, niet de afspraken voor de onderzoeken.</p> \
    <p><i>Exclusief testdossiers.</i></p>',
  },
];

export const DashboardSchadebepalingInfo = [
  {
    key: 'rapport_aantallen',
    title: 'Rapport aantallen',
    description: '<p>Aantal dossiers in de bakken:</p> \
    <ul>\
    <li>Nieuw opgeleverd</li>\
    <li>Gecorrigeerd</li>\
    <li>Ter akkoord SB</li>\
    <li>JZ beoordeeld</li>\
    <li>Controle JZ</li>\
    <li>Schadebepaling on hold</li>\
    </ul>',
  },
];

export const DashboardSchadeafhandelingInfo = [
  {
    key: 'rapport_aantallen',
    title: 'Rapport aantallen',
    description: '<p>Aantal dossiers in de bakken:</p> \
    <ul>\
    <li>Herzien rapport nieuw opgeleverd</li>\
    <li>Herzien rapport gecorrigeerd</li>\
    <li>Herzien rapport teruggezet SBA</li>\
    </ul>',
  },
];

export const OpgeleverdMatrixInfo = [
  {
    key: 'matrix',
    title: 'Opgeleverd Matrix',
    description: '<p>Aantallen opgeleverde rapporten. Een rapport is opgeleverd wanneer de status verstuurd naar aanvrager heeft.</p>',
  },
];

export const TermijnBewakingInfo = [
  {
    key: 'bewaking',
    title: 'Termijn Bewaking',
    description: '<p>Overzicht van dossiers die in behandeling zijn bij de bureaus:</p> \
    <ul>\
    <li>Alle dossiers waarvan de opnamedatum in het  verleden ligt.</li>\
    <li>Exclusief test dossiers</li>\
    </ul>',
  },
];

export const KwaliteitMonitorInfo = [
  {
    key: 'monitor',
    title: 'Kwaliteit Monitor',
    description: 'Overzicht van het aantal keren dat een dossier de status [afgekeurd] of [afgekeurd door schadebepaling] heeft gekregen per status > per actie houder.',
  },
];

export const DossierUitTermijnInfo = [
  {
    key: 'termijn',
    title: 'Dossier uit termijn',
    description: "Overzicht van dossiers die in behandeling zijn bij de bureaus, waarvan de contract KPI's verstreken(Termijn verstreken) zijn of bijna verstrijken(Risico dossiers doorlooptijd).",
  },
];

export const MutatieOWHInfo = [
  {
    key: 'mutatie',
    title: 'Mutatie OWH',
    description: 'Alle dossiers die binnen de geselecteerde data zijn gegund (ingepland bij bureau) en zijn opgeleverd aan IMG.',
  },
];

export const RapportageZaakbegeleiding = [
  {
    key: 'case_mediator_statistics',
    title: 'Rapportage Zaakbegeleiding',
    description: 'Het aantal fysieke, telefonische en ooa afspraken binnen de periode per gebruiker.',
  },
];

export const OutputTCInfo = [
  {
    key: 'output',
    title: "Output TC'ers",
    description: 'Overzicht van verrichte werkzaamheden door TCers. Elke statusovergang wordt hier geteld als een actie. Per TCer kan je per dag zien hoe vaak deze medewerker een dossier heeft verplaatst naar een andere status.',
  },
];

export const OuderdomsanalyseInfo = [
  {
    key: 'analyse',
    title: 'Ouderdomsanalyse',
    description: 'De getoonde cijfers betreffen alle dossiers die nog bij het bureau staan. De ouderdom wordt bepaald aan de hand van de opnamedatum, het dossier telt mee in de maand dat de opname stond ingepland. Met het filter "Exclusief toekomstige dossiers" zijn de toekomstige dossiers uit het overzicht te filteren.',
  },
];

export const DashboardExpertRegisterInfo = [
  {
    key: 'current_expert_register',
    title: 'Huidige Deskundigenregister',
    description: 'Hier ziet u de door IMG goedgekeurde gegevens en competenties van de deskundige.',
  },
  {
    key: 'expert_register_submission',
    title: 'Aanvraag',
    description: 'Hier ziet u <u>alleen</u> wat wordt gewijzigd bij goedkeuren van de aanvraag.',
  },
];

export const VerklaringVanOnafhankelijkheidInfo = [
  {
    key: 'verklaring_van_onafhankelijkheid',
    title: 'Verklaring van Onafhankelijkheid',
    description: '<p>Ondergetekende verklaart met het oog op een eventuele aanwijzing door het IMG als onafhankelijke deskundige in de zin van artikel 12 van de Tijdelijke wet Groningen ten behoeve van de advisering van het IMG omtrent een ingediend verzoek om vergoeding van schade, dat hij/zij:</p> \
    <ul>\
    <li>in de afgelopen 5 jaar niet op basis van een arbeidsovereenkomst, dan wel een aanstelling als ambtenaar werkzaam is geweest bij de NAM, het CVW, de Minister van Economische Zaken en Klimaat, de TCMG of het IMG;</li>\
    <li>in het afgelopen jaar evenmin ten behoeve van de NAM en/of CVW werkzaamheden heeft uitgevoerd, welke bestonden uit de beoordeling van en advisering over oorzakelijk verband en begroting van mijnbouwschade;</li>\
    <li>in de afgelopen 6 maanden geen andere werkzaamheden dan bedoeld onder het vorige punt, gedurende meer dan 50% van de door hem/haar gewerkte tijd ten behoeve van de NAM en/of het CVW heeft uitgevoerd;</li>\
    <li>op dit moment geen zakelijke of persoonlijke relatie heeft met NAM, het CVW of de Minister van Economische Zaken en Klimaat;</li>\
    <li>geen opdracht tot advisering zal aannemen met betrekking tot een gebouw of werk indien hij/zij in het verleden als deskundige ten behoeve van het CVW of NAM bij dat gebouw of werk betrokken is geweest;</li>\
    <li>van oordeel is dat er geen (potentieel) conflict van belangen aanwezig is bij de advisering aan het IMG over de aanvragen tot vergoeding van schade</li>\
    </ul>',
  },
];

export const LegendaStatusDotInfo = [
  {
    key: 'legenda_status_dots',
    title: 'Legenda resterende dagen Contract KPI',
    description: '<ul style="padding: 0;">\
    <li style="list-style-type: none; padding: 0;"><span class="mr-2 status-dot d-inline-block gray"></span><span class="fadedText">Nog nooit teruggezet</span></li>\
    <li style="list-style-type: none; padding: 0;"><span class="mr-2 status-dot d-inline-block blue-magenta"></span><span class="fadedText">Door Voorcontroleur teruggezet</span></li>\
    <li style="list-style-type: none; padding: 0;"><span class="mr-2 status-dot d-inline-block orange"></span><span class="fadedText">Door TC\u0060er teruggezet</span></li>\
    <li style="list-style-type: none; padding: 0;"><span class="mr-2 status-dot d-inline-block red"></span><span class="fadedText">Door IMG teruggezet</span></li>\
    </ul>',
  },
];

export const DashboardWerkvoorbereidingInfo = [
  {
    key: 'Dashboard_werkvoorbeiding_info',
    title: 'Hoe worden de aantallen berekend?',
    description: '<p>De aantallen worden verdeeld in 4 categorieën: Regulier, Aannemersvariant, WoCo en Specials. Deze categorieën zijn als volgt te herleiden: <br> Als een dossier een competentie Special -... (een competentie die begint met Special, er zijn momenteel 12 verschillende) heeft valt deze in de categorie Specials, heeft het geen competentie Special - ... wordt gekeken of het dossier een competentie Aannemersvariant of WoCo heeft, zo ja dan valt deze in categorie aannemersvariant of WoCo. Alle overige dossiers vallen in categorie Regulier.</p>',
  },
];
